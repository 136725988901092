import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavBarComponent } from 'src/app/shared/components/nav-bar/nav-bar.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { ForRolesModule } from 'src/app/shared/directives/for-roles/for-roles.module';



@NgModule({
  declarations: [NavBarComponent],
    imports: [
        CommonModule,
        RouterLink,
        RouterLinkActive,
        ForRolesModule
    ],
  exports: [NavBarComponent]
})
export class NavBarModule { }
