import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-locale-loading',
    templateUrl: './locale-loading.component.html',
    styleUrls: ['./locale-loading.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocaleLoadingComponent {
    @Input() description: string;
    @Input() class: string = 'locale-loader';
}
