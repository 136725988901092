<ejs-dropdownlist [dataSource]='localesList'
                  placeholder='lang'
                  [fields]="{text: (isOpen ?? true) ? 'label' : 'shortCode', value: 'code'}"
                  [value]="selectedLanguage"
                  [itemTemplate]='itemTemplate'
                  [cssClass]="'language-dropdown'"
>
    <ng-template #itemTemplate="" let-data="">
        <ng-container *ngIf="data && data.code">
            <a (click)="saveLanguage(data.code)" class="remove-link-styles language-list-row"
               [href]="'/'+data.code">{{ isOpen ? data.label : data.shortCode }}</a>
        </ng-container>
    </ng-template>
</ejs-dropdownlist>
