import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, tap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { environment } from 'src/environments/environment'; 

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private token: string = '';
    headers: any = {};

    constructor(
        private authService: AuthService,
        private loadingService: LoadingService
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req.url.includes('Auth/login') && sessionStorage?.getItem('access-token')) {
            this.token = JSON.parse(sessionStorage?.getItem('access-token') || '');
        }

        this.headers.Authorization = `Bearer ` + this.token;

        const authReq: HttpRequest<any> = req.clone({
            setHeaders: this.headers
        });
        return next.handle(authReq).pipe(
            catchError((err: HttpErrorResponse): Observable<any> => {
                this.loadingService.hide();
                if (err?.status === 401 && !err.url.includes('Auth/verify')) {
                    this.authService.logOut();
                    return throwError(() => err);
                }
                return throwError(() => err);
            }), tap((res: any): void => {
                if (res.url === `${environment.apiUrl}/Auth` && res.body.id) {
                    this.authService.setUser(res.body);
                }
            })
        );
    }
}
