import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocaleLoadingComponent } from 'src/app/shared/components/locale-loading/locale-loading.component';

@NgModule({
  imports: [CommonModule],
  declarations: [LocaleLoadingComponent],
  exports: [LocaleLoadingComponent]
})
export class LocaleLoadingModule {
}
