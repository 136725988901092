import { Component, DestroyRef } from '@angular/core';
import { DeviceService } from 'src/app/core/services/device.service';
import { NewsBannerService } from 'src/app/core/services/news-banner.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IBannerList } from 'src/app/core/models/news-and-banners.model';

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss']
})
export class BannerComponent {

    public banners: IBannerList[];
    public carouselBanners: IBannerList[] = [];
    public bannerImgCount: number = 4;
    public allowAnimation: boolean = false;
    public mobileIndex: number = 0;
    public mobileBannerImage: string;

    constructor(
        public deviceService: DeviceService,
        public newsBannerService: NewsBannerService,
        private languageService: LanguageService,
        private destroyRef: DestroyRef
    ) {
        this.newsBannerService.getBanners(this.languageService.selectedLanguage)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((bannerList: IBannerList[]) => {
                this.banners = bannerList;
                if (this.deviceService.isMobile) {
                    this.mobileSlider();
                } else {
                    this.carousel();
                }
            });
    }

    carousel(): void {
        this.carouselBanners = JSON.parse(JSON.stringify(this.banners));
        if (this.carouselBanners.length > 3 || this.deviceService.isMobile) {
            setInterval((): void => {
                this.carouselBanners.push(this.carouselBanners[0]);
                this.carouselBanners.shift();
                this.allowAnimation = true;
                this.bannerImgCount++;
            }, 3000);
        }
    }

    mobileSlider(): void {
        if (this.banners?.length) {
            this.mobileBannerImage = 'data:image/png;base64,' + this.banners[this.mobileIndex].image;
            this.mobileIndex++;
            if (this.mobileIndex > this.banners.length - 1) {
                this.mobileIndex = 0;
            }
        }
        setTimeout(this.mobileSlider.bind(this), 6000);
    }


}
