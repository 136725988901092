import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageComponent } from 'src/app/shared/components/language/language.component';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';



@NgModule({
  declarations: [LanguageComponent],
  exports: [
    LanguageComponent
  ],
  imports: [
    CommonModule,
    DropDownListModule
  ]
})
export class LanguageModule { }
