import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from 'src/app/core/guards/auth.guard';
import { EUserRole } from 'src/app/core/enums/user-role.enum';
import { allRoles, rolesWithoutDealer, rolesWithoutUser, rolesWithoutUserAndDealer } from 'src/app/app.constants';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {roles: rolesWithoutUserAndDealer},
        canActivate: [authGuard]
    },
    {
        path: 'news-and-banners',
        loadChildren: () => import('./modules/news-and-banners/news-and-banners.module').then(m => m.NewsAndBannersModule),
        data: {roles: rolesWithoutUserAndDealer},
        canActivate: [authGuard]
    },
    {
        path: 'users',
        loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
        data: {roles: rolesWithoutUser},
        canActivate: [authGuard]
    },
    {
        path: 'dealers',
        loadChildren: () => import('./modules/dealers/dealers.module').then(m => m.DealersModule),
        data: {roles: rolesWithoutUserAndDealer},
        canActivate: [authGuard]
    },
    {
        path: 'pos',
        loadChildren: () => import('./modules/pos/pos.module').then(m => m.PosModule),
        data: {roles: rolesWithoutUser},
        canActivate: [authGuard]
    },
    {
        path: 'providers',
        loadChildren: () => import('./modules/providers/providers.module').then(m => m.ProvidersModule),
        data: {roles: rolesWithoutUserAndDealer},
        canActivate: [authGuard]
    },
    {
        path: 'products',
        loadChildren: () => import('./modules/products/products.module').then(m => m.ProductsModule),
        data: {roles: rolesWithoutUserAndDealer},
        canActivate: [authGuard]
    },
    {
        path: 'top-up',
        loadChildren: () => import('./modules/top-up/top-up.module').then(m => m.TopUpModule),
        data: {roles: rolesWithoutDealer},
        canActivate: [authGuard]
    },
    {
        path: 'future-top-up',
        loadChildren: () => import('./modules/top-up/top-up.module').then(m => m.TopUpModule),
        data: {roles: rolesWithoutDealer},
        canActivate: [authGuard]
    },
    {
        path: 'recurring-top-up',
        loadChildren: () => import('./modules/top-up/top-up.module').then(m => m.TopUpModule),
        data: {roles: rolesWithoutDealer},
        canActivate: [authGuard]
    },
    {
        path: 'bulk-top-up',
        loadChildren: () => import('./modules/bulk-top-up/bulk-top-up.module').then(m => m.BulkTopUpModule),
        data: {roles: rolesWithoutDealer},
        canActivate: [authGuard]
    },
    {
        path: 'cancel-transaction',
        loadChildren: () => import('./modules/cancel-transaction/cancel-transaction.module').then(m => m.CancelTransactionModule),
        data: {roles: allRoles},
        canActivate: [authGuard]
    },
    {
        path: 'reports',
        loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule),
        data: {roles: allRoles},
        canActivate: [authGuard]
    },
    {
        path: 'financial-transaction',
        loadChildren: () => import('./modules/financial-transaction/financial-transaction.module').then(m => m.FinancialTransactionModule),
        data: {roles: rolesWithoutUser},
        canActivate: [authGuard]
    },
    {
        path: '',
        redirectTo: '/top-up',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: '/top-up',
        pathMatch: 'full'
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
