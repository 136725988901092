<div class="main" [ngClass]="{'mobile': deviceService.isMobile}">

    <div class="header">
        <div *ngIf="isHaveBackRoute" (click)="routeBack()" class="icon-btn">
            <img src="./assets/images/icons/arrow-back.svg" alt="">
        </div>
        <div class="header__and__back">
            <h2 *ngIf="title">{{title}}</h2>
            <p *ngIf="description" class="description" [innerHTML]="description | safeHtml: 'html'"></p>
        </div>
    </div>

    <ng-content></ng-content>
</div>
