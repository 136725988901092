<div class="main-news-feed" [class.rtl]="direction === 'rtl'">
    <div class="news" i18n>News</div>
    <div class="marquee">
      <div class="ticker-wrap">
        <div class="ticker" [appRunningLine]="newsData">
          <div class="ticker__item" *ngFor="let news of newsData" [innerHTML]="news.text | safeHtml">
          </div>
        </div>
      </div>
    </div>
  </div>
