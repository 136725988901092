import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageWrapperComponent } from './page-wrapper.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';


@NgModule({
    declarations: [PageWrapperComponent],
    imports: [
        CommonModule,
        PipesModule
    ],
    exports: [PageWrapperComponent]
})
export class PageWrapperModule {
}
