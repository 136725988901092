<div class="nav-bar">
    <div *ngIf="isOpen" class="user-info__block">
        <div class="user-info">
            <div class="user-name__first-letter">
                {{userService?.user?.firstname[0]}}{{userService?.user?.lastname[0]}}
            </div>
            <div class="user-name__user-id">
                <div class="user-name">
                    {{userService?.user?.firstname}}
                    {{userService?.user?.lastname}}
                </div>
                <div class="user-id">
                    ID {{userService?.user?.id}}
                </div>
            </div>
        </div>
        <div *ngIf="userService?.user?.clientName">{{userService?.user?.clientName}}</div>
        <div *ngIf="userService?.user?.role !== EUserRole.ADMIN; else adminBalance" class="user-balance">
            <div class="balance">
                <div i18n>Balance:</div>
                <div dir="ltr">₪ {{state.balance?.balanceAmount || '0' | number: '1.2-2'}}</div>
            </div>
            <div class="credit">
                <div i18n>Credit:</div>
                <div dir="ltr">₪ {{state.balance?.credit || '0' | number: '1.2-2'}}</div>
            </div>
            <div class="available">
                <div class="bold" i18n>Available:</div>
                <div class="bold" dir="ltr">
                    ₪ {{state.balance?.total || '0' | number: '1.2-2'}}</div>
            </div>
        </div>
        <ng-template #adminBalance>
            <div class="user-balance" *ngIf="Array.isArray(state.balance)">
                <div class="balance" *ngFor="let pb of state.balance | slice:0: limit">
                    <div class="column">{{pb.providerName || 'provider'}}:</div>
                    <div class="column" dir="ltr">₪ {{pb.balance | number: '2.2-2'}}</div>
                </div>
            </div>
            <div (click)="showMore()" *ngIf="state?.balance?.length > 3" class="show-more-balance">
                {{providerActionBtnName}}
                <span><img [class.reverse]="!showHIdeToggle" height="16px" width="16px"
                           src="./assets/images/icons/dropDown-icon.png" alt=""></span>
            </div>
        </ng-template>
    </div>
    <div *ngIf="!isOpen" class="user-info__block__small">
        <div class="user-info">
            <div class="user-name__first-letter">
                {{userService.user?.firstname[0]}}{{userService.user?.lastname[0]}}
            </div>
        </div>
    </div>
    <div class="navigation-menu__block">
        <ng-container *ngFor="let item of menuItems">
            <div
                    *forRoles="{roles: item.roles, url: item.url}"
                    [routerLink]="['/', item.url]"
                    routerLinkActive="active-route"
                    [ngClass]="{'minimized__navigation-btn': !isOpen}"
                    class="navigation-btn">
                <div>
                    <img [src]="currentRoute.includes('/'+item.url) ? item.whiteImg : item.img" alt="">
                </div>
                <div *ngIf="isOpen">
                    {{item.text}}
                </div>
            </div>
        </ng-container>
    </div>
    <hr>
    <div class="support__log-out">
        <a [href]="'tel:' + supportNumber" [ngClass]="{'minimized__navigation-btn': !isOpen}"
           class="navigation-btn color-primary remove-link-styles">
            <div>
                <img src="./assets/images/icons/support.svg" alt="">
            </div>
            <div *ngIf="isOpen">
                {{supportNumber}}
            </div>
        </a>
        <div [ngClass]="{'minimized__navigation-btn': !isOpen}" class="navigation-btn" (click)="logOut()">
            <div>
                <img src="./assets/images/icons/log-out.svg" alt="">
            </div>
            <div *ngIf="isOpen" i18n>
                Log Out
            </div>
        </div>

    </div>
</div>
