import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
    selector: 'app-otp-input',
    templateUrl: './otp-input.component.html',
    styleUrls: ['./otp-input.component.scss']
})
export class OtpInputComponent implements OnInit{
    @Input() label: string;
    @Input() labelClass: string;
    @Input() control: FormControl | AbstractControl | any;
    @Input() disabled: boolean = false;

    get isRequired(): boolean {
        if (!this.control?.validator) {
            return false;
        }
        const validator = this.control?.validator({} as AbstractControl);
        return !!(validator && validator?.required);
    }

    ngOnInit(): void {
        this.disabled ? this.control?.disable() : this.control?.enable();
    }
}
