import { Component, Input, TemplateRef } from '@angular/core';
import { FieldSettingsModel, FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { AbstractControl, FormControl } from '@angular/forms';
import { EmitType } from '@syncfusion/ej2-base';
import { Query } from '@syncfusion/ej2-data';

@Component({
    selector: 'app-multiselect',
    templateUrl: './multiselect.component.html',
    styleUrls: ['./multiselect.component.scss']
})
export class MultiselectComponent {
    @Input() fields: FieldSettingsModel = {text: 'text', value: 'id'};
    @Input() control: FormControl | AbstractControl | any;
    @Input() label: string;
    @Input() labelClass: string;
    @Input() data: { [p: string | number]: any }[] = [];
    @Input() placeholder: string = 'Select';
    @Input() value: string = '';
    @Input() class: string = '';
    @Input() showSelectAll: boolean = true;
    @Input() allowFiltering: boolean = false;
    @Input() itemTemp: TemplateRef<any>;
    @Input() valTemp: TemplateRef<any>;
    @Input() isLoading: boolean = false;
    public height: string = '220px';

    get isRequired(): boolean {
        if (!this.control.validator) {
            return false;
        }
        const validator = this.control?.validator({} as AbstractControl);
        return !!(validator && validator?.required);
    }

    public onFiltering: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        let query = new Query();
        query = (e.text !== '') ? query.where('name', 'contains', e.text, true) : query;
        e.updateData(this.data, query);
    }
}
