import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';

import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MenuModule, SidebarModule, ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { ListViewAllModule } from '@syncfusion/ej2-angular-lists';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DropdownsModule } from 'src/app/shared/components/dropdowns/dropdowns.module';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { BannerModule } from 'src/app/shared/components/banner/banner.module';
import { LanguageModule } from 'src/app/shared/components/language/language.module';
import { NavBarModule } from 'src/app/shared/components/nav-bar/nav-bar.module';
import { NewsModule } from 'src/app/shared/components/news/news.module';
import { AuthInterceptor } from 'src/app/core/interceptors/auth.interceptor';
import { LoadingModule } from 'src/app/shared/components/loading/loading.module';
import { OutsideClickModule } from 'src/app/shared/directives/outside-click/outside-click.module';
import { MatDialogModule } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { OtpCheckModule } from 'src/app/shared/components/otp-check/otp-check.module';
import { JwtModule } from '@auth0/angular-jwt';

export function OTPTokenGetter(): string {
    return localStorage.getItem('otp_token');
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot([]),
        HttpClientModule,
        ToolbarModule,
        SidebarModule,
        MenuModule,
        ListViewAllModule,
        GridModule,
        ButtonModule,
        DropdownsModule,
        DropDownListModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BannerModule,
        LanguageModule,
        NavBarModule,
        NewsModule,
        LoadingModule,
        OutsideClickModule,
        MatDialogModule,
        RichTextEditorAllModule,
        OtpCheckModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: OTPTokenGetter,
            },
        }),
    ],
    providers: [{provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}, DatePipe],
    bootstrap: [AppComponent]
})
export class AppModule {
}
