import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsComponent } from 'src/app/shared/components/news/news.component';
import { OutsideClickModule } from 'src/app/shared/directives/outside-click/outside-click.module';
import { RunningLineModule } from 'src/app/shared/directives/running-line/running-line.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';


@NgModule({
    declarations: [NewsComponent],
    imports: [
        CommonModule,
        OutsideClickModule,
        RunningLineModule,
        PipesModule
    ],
    exports: [NewsComponent]
})
export class NewsModule {
}
