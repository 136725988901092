import { DestroyRef, Injectable, Injector } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { map, Observable, tap, throwError } from 'rxjs';
import { INewPassword, IUser, UserModel } from 'src/app/core/models/user.model';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from 'src/app/core/services/user.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from 'src/app/core/services/loading.service';
import { StateService } from 'src/app/core/services/state.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ToastComponent, ToastUtility } from '@syncfusion/ej2-angular-notifications';
import { LanguageService } from 'src/app/core/services/language.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService extends ApiService {
    public user: UserModel;
    private up: string = '';
    public toastObj?: ToastComponent;

    get getPass(): string {
        return this.up;
    }

    set setPass(up: string) {
        this.up = up;
    }

    constructor(
        protected override injector: Injector,
        private userService: UserService,
        private router: Router,
        private stateService: StateService,
        private matDialog: MatDialog,
        private loadingService: LoadingService,
        private destroyRef: DestroyRef,
        private languageService: LanguageService
    ) {
        super(injector);
    }

    login(body: any): Observable<IUser> {
        return this.post<IUser>(['Auth', 'login'], body)
            .pipe(
                map((obj: IUser): UserModel => new UserModel(obj)),
                tap((user: UserModel): void => {
                    this.setUser(user);
                }),
                catchError((error: HttpErrorResponse) => {
                    this.setUser(null);
                    this.toastObj = ToastUtility.show(
                        {
                            content: $localize`Login failed, please check details`,
                            timeOut: 2000,
                            icon: 'warning-icon',
                            position: {X: this.languageService.selectedLanguage === 'en' ? 'Right' : 'Left', Y: 'Top'},
                            showCloseButton: true,
                            cssClass: 'e-toast-warning'
                        }) as ToastComponent;
                    return throwError(() => new Error(error.message));
                }),
            );
    }

    public setUser(user: UserModel): void {
        if (user?.id) {
            if (!this.router.url.includes('/auth/set-password') && !this.router.url.includes('/auth')) {
                this.loadingService.show();
            }
            this.userService.isLoggedIn = true;
            this.user = this.userService.user = new UserModel(user);
            this.userService.currentUser$.next(new UserModel(user));
            this.stateService.initCaches(user);
            this.stateService.getBalance(user)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe();
        } else {
            this.userService.isLoggedIn = false;
            this.user = this.userService.user = null;
            this.userService.currentUser$.next(null);
            this.matDialog.closeAll();
            sessionStorage.clear();
            this.router.navigate(['/auth']);
        }
    }

    public logOut(): void {
        this.setUser(null);
    }

    setNewPassword(body: INewPassword): Observable<IUser> {
        return this.post<IUser>(['Auth', 'updatepw'], body);
    }
}
