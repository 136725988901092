import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownComponent } from './dropdown/dropdown.component';
import { CheckBoxSelectionService, DropDownListModule, MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { ReactiveFormsModule } from '@angular/forms';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { LocaleLoadingModule } from "../locale-loading/locale-loading.module";

@NgModule({
    declarations: [DropdownComponent, MultiselectComponent],
    exports: [
        DropdownComponent,
        MultiselectComponent
    ],
    providers: [CheckBoxSelectionService],
    imports: [
        CommonModule,
        DropDownListModule,
        ReactiveFormsModule,
        MultiSelectModule,
        CheckBoxModule,
        LocaleLoadingModule
    ]
})
export class DropdownsModule { }
