import { Injectable, Injector } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { IBannerList, IViewNews } from 'src/app/core/models/news-and-banners.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NewsBannerService extends ApiService {
    constructor(
        protected override injector: Injector
    ) {
        super(injector);
    }

    getBanners(lang: string): Observable<IBannerList[]> {
        return this.get<IBannerList[]>(['Banner', lang]);
    }

    getNews(lang: string): Observable<IViewNews[]> {
        return this.get<IViewNews[]>(['News', lang]);
    }
}
