import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, take } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    protected baseURL: string = 'https://api.harel.software/api';
    protected httpClient: HttpClient;

    constructor(protected injector: Injector) {
        if (environment.production) {
            this.baseURL = 'https://api.rtime.co.il/api';; // Production
        }
        this.httpClient = this.injector.get(HttpClient);
    }

    public request<T>(method: string, url: string, options: any): Observable<any> {

        let headers = options.headers instanceof HttpHeaders ? options.headers : new HttpHeaders();
        // Append the no-cache headers
        headers = headers.set('Cache-Control', 'no-cache')
                         .set('Pragma', 'no-cache')
                         .set('Expires', '0');
        
        // Assign headers back to options
        options.headers = headers;
        return this.httpClient.request<T>(method, url, options).pipe(
            take(1),
            catchError((error) => {
                let errorMessage = 'An error occurred';
                if (error.error instanceof ErrorEvent) {
                    // Client-side error
                    errorMessage = `Error: ${error.error.message}`;
                } else {
                    // Server-side error
                    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                }
                return throwError(() => new Error(errorMessage));
            })
        );
    }

    head<T>(url: string, params = {}): Observable<T> {
        return this.request('head', url, params);
    }

    get<T>(url: string | Array<string | number>, params = {}): Observable<T> {
        return this.request('get', this.pathUrl(url), params);
    }

    patch<T>(url: string, params = {}): Observable<T> {
        return this.request('patch', url, params);
    }

    post<T>(url: string | Array<string | number>, body = {}, params = {}): Observable<T> {
        return this.request('post', this.pathUrl(url), {body, params});
    }

    put<T>(url: string | Array<string | number>, body = {}, params?: any): Observable<T> {
        return this.request('put', this.pathUrl(url), {body, params});
    }

    delete<T>(url: string | Array<string | number>, params = {}, body?: any): Observable<T> {
        return this.request('delete', this.pathUrl(url), {body, params});
    }

    pathUrl(reqUrl: string | Array<string | number>): string {
        let value = JSON.parse(JSON.stringify(reqUrl));
        if (Array.isArray(value)) {
            if (!!this.baseURL) {
                value.unshift(this.baseURL);
            }

            value = (value as any[]).join('/');
        }else {
            value = this.baseURL + '/' + value;
        }

        return value;
    }
}
