<label [for]="label" class="label" [class]="labelClass">{{ label }}</label><span class="err-msg-text fz-16" *ngIf="isRequired">*</span>

<ng-otp-input dir="ltr"
        [config]="{
        length: 6,
        placeholder: '#',
        allowNumbersOnly: true,
        containerClass: 'otp-container'
        }"
        [formCtrl]="control"
></ng-otp-input>
