import { Component, DestroyRef, ViewEncapsulation } from '@angular/core';
import { NewsBannerService } from 'src/app/core/services/news-banner.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IViewNews } from 'src/app/core/models/news-and-banners.model';

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NewsComponent {
    newsData: IViewNews[];
    direction: 'ltr' | 'rtl' = 'ltr'; // Default direction

    constructor(
        public newsBannerService: NewsBannerService,
        private languageService: LanguageService,
        private destroyRef: DestroyRef
    ) {
        this.direction = languageService.direction;
        this.newsBannerService.getNews(languageService.selectedLanguage)
            .pipe(takeUntilDestroyed(destroyRef))
            .subscribe((news: IViewNews[]): void => {
                this.newsData = news;
            });
    }
}
