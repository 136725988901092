import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { DeviceService } from 'src/app/core/services/device.service';

@Component({
  selector: 'app-page-wrapper',
  templateUrl: './page-wrapper.component.html',
  styleUrls: ['./page-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageWrapperComponent {

  @Input() title: string = 'Title';
  @Input() description: string = '';
  @Input() isHaveBackRoute: boolean = true;

constructor(
    public deviceService: DeviceService,
    private location: Location,
) {
}

  public routeBack(): void {
    this.location.back();
  }
}
