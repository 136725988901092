<label [for]="label" class="label" [class]="labelClass">{{label}}</label>
<span class="err-msg-text fz-16"  *ngIf="isRequired">*</span>
<div class="drop-down-block" [ngClass]="{'mt-8': !!label}">
    <ejs-multiselect
            id='multiSelectElement'
            [dataSource]='data'
            [fields]='fields'
            (filtering)='onFiltering($event)'
            [formControl]="control"
            mode='CheckBox'
            class="ejs-dropdownlist"
            [placeholder]='placeholder'
            [showDropDownIcon]='true'
            [showSelectAll]='showSelectAll'
            [popupHeight]='height'
            [allowFiltering] = 'allowFiltering'
            cssClass="{{class}} {{control.invalid && control.touched ? 'input-error' : ''}}"
    >
        <ng-template #itemTemplate let-dataItem>
            <ng-container *ngTemplateOutlet="itemTemp; context: { dataItem: dataItem }"></ng-container>
        </ng-template>
        <ng-template #valueTemplate let-dataItem>
            <ng-container *ngTemplateOutlet="valTemp; context: { dataItem: dataItem }"></ng-container>
        </ng-template>
    </ejs-multiselect>
    <div class="drop-down-loading" *ngIf="isLoading"><app-locale-loading class="btn-loader-blue"></app-locale-loading></div>
</div>
