import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable({
    providedIn: 'root'
})
export class OtpCheckService extends ApiService {

    constructor(
        protected override injector: Injector
    ) {
        super(injector);
    }

    sendOTP(userId: number): Observable<any> {
        return this.post<any>(['Auth', 'OTP', userId], {id: userId});
    }

    verifyRoute(body: any): Observable<any> {
        return this.post<any>(['Auth', 'verify'], body);
    }
}
