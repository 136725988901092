import { Component, Input } from '@angular/core';
import { localesList } from 'src/app/app.constants';

@Component({
    selector: 'app-language',
    templateUrl: './language.component.html',
    styleUrls: ['./language.component.scss']
})
export class LanguageComponent {

    protected readonly localesList: { [p: string | number]: any }[] = localesList;
    @Input() isOpen: boolean;
    @Input() selectedLanguage: string;

    public saveLanguage(code: string): void {
        localStorage.setItem('language', code);
    }
}
