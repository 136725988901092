import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OtpCheckComponent } from './otp-check.component';
import { OtpInputComponent } from './otp-input/otp-input.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { PageWrapperModule } from 'src/app/shared/components/page-wrapper/page-wrapper.module';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
    declarations: [
        OtpCheckComponent,
        OtpInputComponent
    ],
    imports: [
        CommonModule,
        NgOtpInputModule,
        PageWrapperModule,
        ReactiveFormsModule
    ]
})
export class OtpCheckModule {
}
